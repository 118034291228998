.alertCustom{
    z-index: 1;
    position: fixed;
    width: 100%;
    text-align: center;
    right: 0px;
}

@media screen and (max-width: 280px){
    .alertCustom {
        top : 45px !important;
    }
}
