.produtos{
    padding-top: 7rem !important;
}

.title{
    line-height: 53px;
    text-align: center;
}

.productImg{
    width: 90%;
    display: block;
    margin: auto;
}

.catDescription{
    height: 5rem;
}