.contactos{
    min-height: 50rem;
    padding-top: 7rem !important;
}

.butttonSend{
    position: absolute;
    right: 15px;
    background: rgba(14,67,76,255) !important;
    color: white;
}

.customMargin{
    margin-left: 10%;
}

.blackColor{
    color: black !important;
}

/* media queries */
@media screen and (max-width: 1024px) {
    .contactos {
        min-height: 75rem;
    }
}

@media screen and (max-width: 992px) {
    .contactos {
        min-height: 73rem;
    }
}

@media screen and (min-width: 575px) and (max-width: 700px) {
    .contactos {
        min-height: 66rem;
    }
}

@media screen and (min-width: 500px) and (max-width: 575px) {
    .contactos {
        min-height: 86rem;
    }
}

@media screen and (min-width: 420px) and (max-width: 500px) {
    .contactos {
        min-height: 76rem;
    }
}

@media screen and (min-width: 370px) and (max-width: 420px) {
    .contactos {
        min-height: 76rem;
    }
}



