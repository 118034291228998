.notFound{
    padding-top: 10rem !important;
    min-height: 50rem;
}

.customSize{
    font-size: 10rem;
}

.btn-success{
    background-color: rgba(14,67,76,255) !important;
    border-color: black !important;
}

.centerText{
    text-align: center !important;
}

.containerCustom{
    display: flex;
    justify-content: center;
}

.child {
    margin: 0 auto;
  }