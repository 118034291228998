.sobreNos{    
    min-height: 74rem;
    padding-top: 7rem !important;
}

.fontLarge{
    font-size : 'large'
}

.carousel-inner{
    max-height: 49rem;
    min-height: 19rem;
}

.customHeight{
    max-height: 49rem;
    min-height: 19rem;
}

.card-body{
    background-color: #efefef !important;
}

.textCenter{
    text-align: center;
}

.shadowText{
    text-shadow: 0 0 0.2em black, 0 0 0.2em black, 0 0 0.2em black;
}

/* 
media queries */
/* @media screen and (max-width: 1300px) {
    .carousel-inner{
        height: 47rem;
    }
    
    .customHeight{
        height: 47rem;
    }
}

@media screen and (max-width: 1300px) {
    .carousel-inner{
        height: 39rem;
    }
    
    .customHeight{
        height: 39rem;
    }
}

@media screen and (max-width: 1280px) {
    .carousel-inner{
        height: 37rem;
    }
    
    .customHeight{
        height: 37rem;
    }
}

@media screen and (max-width: 1280px) {
    .carousel-inner{
        height: 35rem;
    }
    
    .customHeight{
        height: 35rem;
    }
}

@media screen and (max-width: 1024px) {
    .carousel-inner{
        height: 33rem;
    }
    
    .customHeight{
        height: 33rem;
    }
}

@media screen and (max-width: 875px) {
    .carousel-inner{
        height: 27rem;
    }
    
    .customHeight{
        height: 27rem;
    }
}

@media screen and (max-width: 620px) {
    .carousel-inner{
        height: 25rem;
    }
    
    .customHeight{
        height: 25rem;
    }
}
*/
@media screen and (max-width: 485px) {
    .carousel-inner{
        height: 20rem;
    }
    
    .customHeight{
        height: 20rem;
    } 
} 


