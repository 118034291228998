.navbar {
   
}

.customBg {
    background : rgba(14,67,76,255) !important; 
    /* background : white !important; */
    /* border-bottom: 3px solid black; */
}

.navbar-nav{
    font-size: large;
    font-weight: bold;
}

.nav-link{
    /* color : rgba(14,67,76,255) !important; */
}

.nav-link:hover{
    color : white !important; 
    background : rgba(14,67,76,255) !important;
    /* font-size: larger; */
    /* text-decoration: underline; */
}

.navbar-toggler{
    /* background : rgba(14,67,76,255) !important; */
}

.activeTab{
    color : white !important;
    /* background : rgba(14,67,76,255) !important; */
}

.brandImg{
    height: 6rem !important;
    position: relative;
    left: 6vw;
}

.brand{
    width: 14rem;
}

.fontXLarge{
    font-size: larger;
}

/* medias queries */
@media screen and (min-width: 395px) and (max-width: 991px) {
    .nav-link {
        text-align: right;
        padding-right: 30px !important;
    }

    .navbar-toggler {
        position: relative;
        right: 30px;
    }
}

@media screen and (max-width: 395px) {
    .navbar-toggler {
        position: relative;
        right: 30px;
    }
    .nav-link {
        text-align: right;
        padding-right: 30px !important;
    }
}

@media screen and (max-width: 280px){
    .brand{
        width: 5rem !important;
    }
}


