.footerStyle{
    background-color : #222528;
    color: white;
    min-height: 10rem;
}

.height100{
    height: 100%;
    width: 100%;
    margin: 0px;
}

.imgFooter {
    height: 2rem;
}

.whiteColor{
    color: white !important;
}

.displayBlock{
    display: block;
}

.text-end{
    text-align: end !important;
}

.text-start{
    text-align: start !important;
}

.customMarginRight {
    margin-right: 35px !important;
}

.customMarginLeft {
    margin-left: 35px !important;
}

.fontSizeSmall{
    font-size: small !important;
}