.candidaturas{
    min-height: 68rem;
    padding-top: 7rem !important;
}

.imgCustomH{
    width: 100%;
}

/* media queries */
@media screen and (min-width: 1200px) {
    .candidaturas {
        min-height: 78rem;
    }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
    .candidaturas {
        min-height: 75rem;
    }
}